import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {path: '/', name: 'Home', meta: {title: "首页", isLogin: true}, component: () => import('../views/Home.vue')},
    {path: '/Convert', name: 'Convert', meta: {title: "兑换", isLogin: true}, component: () => import('../views/Convert.vue')},
    {path: '/ConvertDetail', name: 'ConvertDetail', meta: {title: "权益详情", isLogin: true}, component: () => import('../views/ConvertDetail.vue')},
    {path: '/Orderdetail', name: 'Orderdetail', meta: {title: "订单详情", isLogin: true}, component: () => import('../views/Orderdetail.vue')},
    {path: '/ProductDetail', name: 'ProductDetail', meta: {title: "礼品详情", isLogin: true}, component: () => import('../views/ProductDetail.vue')},
    {path: '/MatterSubmit', name: 'MatterSubmit', meta: {title: "实物确认", isLogin: true}, component: () => import('../views/MatterSubmit.vue')},
    {path: '/TicketsSubmit', name: 'TicketsSubmit', meta: {title: "实物确认", isLogin: true}, component: () => import('../views/TicketsSubmit.vue')},
    {path: '/AirportSubmit', name: 'AirportSubmit', meta: {title: "实物确认", isLogin: true}, component: () => import('../views/AirportSubmit.vue')},
    {path: '/Success', name: 'Success', meta: {title: "兑换成功", isLogin: true}, component: () => import('../views/Success.vue')},
    {path: '/Address', name: 'Address', meta: {title: "地址列表", isLogin: true}, component: () => import('../views/Address.vue')},
    {path: '/CreateAddress', name: 'CreateAddress', meta: {title: "添加收货地址", isLogin: true}, component: () => import('../views/CreateAddress.vue')},
    {path: '/Order', name: 'Order', meta: {title: "兑换中心", isLogin: true}, component: () => import('../views/Order.vue')},
    {path: '/Login', name: 'Login', meta: {title: "登录", isLogin: false}, component: () => import('../views/Login.vue')},
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title + " - 优卓权益中心"
    }
    let token = localStorage.getItem("token");
    if (to.meta.isLogin && !token) {
        router.push("Login");
    } else {
        next();
    }
})

export default router
