import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import 'lib-flexible';

import router from './router'

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant)

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
